@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--primary: 0 0% 65%;
		--primary-hover: 0 0% 25%;

		--primary-light-grey: 0 0% 65%;
		--primary-medium-grey: 0 0% 38%;

		--primary-foreground: 210 40% 98%;

		--secondary: 210 40% 96.1%;
		--secondary-foreground: 0 0% 65%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--accent: 210 40% 96.1%;
		--accent-foreground: 0 0% 65%;

		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--ring: 222.2 84% 4.9%;

		--radius: 0.5rem;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;

		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;

		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;

		--primary: 210 40% 98%;
		--primary-foreground: 0 0% 65%;

		--secondary: 217.2 32.6% 17.5%;
		--secondary-foreground: 210 40% 98%;

		--muted: 217.2 32.6% 17.5%;
		--muted-foreground: 215 20.2% 65.1%;

		--accent: 217.2 32.6% 17.5%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 210 40% 98%;

		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--ring: 212.7 26.8% 83.9%;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
}
* {
	box-sizing: border-box;
	scroll-behavior: smooth;
}
html,
body {
	-webkit-overflow-scrolling: auto;
}
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
	display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.overlay::hover::after {
	content: " ";
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: black;
	z-index: 2;
}

.hide-blur {
	transition: backdrop-filter 0.3s 0.3s;
	backdrop-filter: blur(0) opacity(0%) grayscale(20%);
}

.show-blur {
	transition: backdrop-filter 0.3s;
	backdrop-filter: blur(10px) opacity(100%) grayscale(20%);
}

.snaps-inline {
	scroll-snap-type: inline mandatory;
	overflow-x: auto;
	overflow-y: hidden;
}
.snaps-inline > * {
	scroll-snap-align: start;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* #wrapper {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	position: relative;
} */

/* iframe {
	position: absolute;
	top: 50%;
	left: 50%;

	height: fit;
	objectfit: fill;
	transform: translate(-50%, -50%);

	@media (min-aspect-ratio: 16/9) {
		width: 177.78vh - 16px;
	}

	@media (max-aspect-ratio: 16/9) {
		height: 56.25vw;
	}
} */
